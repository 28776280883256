
























































































































































































































































































import { Component, Ref, Mixins, Watch } from "vue-property-decorator";
import { ReceiptActingMail } from "./types";
import { Agreement, OfficeRec2Count } from "@/model/agreement";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import OfficeStaffChangeDialog from "@/components/contractmanagement/OfficeStaffChangeDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  AgreementHistory,
  DEFAULT_AGREEMENT,
} from "@/model/agreement/agreement";
import { Office, DEFAULT_OFFICE } from "@/model/office";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { Choice } from "@/types";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";
import {
  ChikenLinkInfo,
  DEFAULT_CHIKEN_LINK_INFO,
} from "@/model/agreement/chikenLink";
import OutputExcelDialog from "@/components/contractmanagement/OutputExcelDialog.vue";
import InputExcelDialog from "@/components/contractmanagement/InputExcelDialog.vue";

@Component({
  components: {
    ChangeContractDialog,
    OfficeInfo,
    AppAuthButton,
    OfficeStaffChangeDialog,
    OutputExcelDialog,
    InputExcelDialog,
  },
})
export default class ContractDetails extends Mixins(AxiosMixin, UtilMixin) {
  @Ref("contract-dialog")
  private readonly changeContractDialog!: ChangeContractDialog;

  @Ref("office-staff-change-dialog")
  private readonly officeStaffChangeDialog!: OfficeStaffChangeDialog;

  /** 初回ロード完了フラグ */
  private initLoadingDone = false;

  /** 選択中の事業所ID */
  private selectedOfficeId = 0;

  private agreement: Agreement = { ...DEFAULT_AGREEMENT };
  private agreementHistory: AgreementHistory = {} as AgreementHistory;

  private receipt_acting_mails: ReceiptActingMail[] = [];

  /** 勤怠連携情報(全事業所分) */
  private kintaiLinkInfos: KintaiLinkInfo[] = [];
  /** 治験連携情報(全事業所分) */
  private chikenLinkInfos: ChikenLinkInfo[] = [];

  /** 記録書II集計情報 */
  private totalRec2CountData: OfficeRec2Count[] = [];

  /** 記録書II集計情報(利用者別) */
  private totalRec2PatientCountData: OfficeRec2Count[] = [];

  /** iBowKINTAI連携先事業所ID選択ダイアログ */
  private isOpenKintaiReplicationOutputDialog = false;

  /** iBowKINTAI連携Excelファイル選択ダイアログ */
  private isOpenKintaiReplicationInputDialog = false;

  private ibow_rec_btn_ctrl = "無効";

  /** 記録書II請求情報(利用者別)取得フラグ */
  private need_rec2_patient = 0;

  // 企業契約の承認作業が終わっていれば情報編集が可能
  private get canEditContract() {
    return this.agreementHistory.confflag == 1;
  }

  private get canEditOffice() {
    return (office: Office) => office.billing_info.confflag == 1;
  }

  //企業編集ボタンクリック
  private toggleEditMode(id: number) {
    this.$router.push({ path: "/contractmanagement/edit/" + id });
  }

  // 企業情報変更履歴ボタンクリック
  private onClickAgreementHistoryViewer() {
    this.$router.push({
      path: "/agreement/history/list/" + this.$route.params.id,
    });
  }

  //事業所編集ボタンクリック
  private clickOfficeEdit(id: number) {
    this.$router.push({
      path: "/office/edit/" + this.$route.params.id + "?office_id=" + id,
    });
  }

  //事業所契約履歴ボタンクリック
  private clickOfficeHistoryShow(id: number) {
    this.$router.push({
      path:
        "/office/history/list/" + this.$route.params.id + "?office_id=" + id,
    });
  }

  //ほのぼのNEXT設定ボタンクリック
  private clickeHonobonoSetting(id: number) {
    this.$router.push({
      path: "/honobono/office/list/" + id,
    });
  }

  //けあログっと利用の事業所選択ボタンクリック
  private clickPortalSetting(id: number) {
    this.$router.push({
      path: "/portal/select/office/list/" + this.$route.params.id + "/" + id,
    });
  }

  //変更届確認クリック
  private clickChangeContract() {
    this.changeContractDialog.open();
  }

  // 変更届の承認完了時
  private onCompleteChangeContract(historyAfter: AgreementHistory) {
    this.agreement.agreement_historys = [historyAfter];
  }

  //アカウント切り替えクリック
  private clickUserChange() {
    this.officeStaffChangeDialog.open(this.agreement.co_code);
  }

  public async created() {
    await this.fetchAgreement(Number(this.$route.params.id));
    this.fetchKintaiLinkInfos();
    this.fetchChikenLinkInfos();
    this.initLoadingDone = true;
  }

  @Watch("agreement", { deep: true })
  private onChangeAgreement() {
    // Agreement変更時はHistoryなどを再計算
    this.agreementHistory = this.agreement.agreement_historys.filter(
      (history: AgreementHistory) => history.confflag <= 1
    )[0];

    this.receipt_acting_mails = this.agreementHistory.receipt_acting_mails;
    //iBowレセプトボタン制御表示
    if (this.agreementHistory.ibow_rec_btn_ctrl) {
      this.ibow_rec_btn_ctrl = "有効";
      if (
        this.agreementHistory.ibow_rec_btn_ctrl_start != "" ||
        this.agreementHistory.ibow_rec_btn_ctrl_end
      ) {
        this.ibow_rec_btn_ctrl +=
          "　（" +
          this.agreementHistory.ibow_rec_btn_ctrl_start +
          "　〜　" +
          this.agreementHistory.ibow_rec_btn_ctrl_end +
          "）";
      }
    }
  }

  /** 契約情報取得 */
  private async fetchAgreement(id: number) {
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/agreements/get",
        { is_display_confirm: 1, ids: [id], need_rec2: 1, need_master: 1 },
        (res) => {
          this.agreement = res.data.agreements[0];
          this.totalRec2CountData = this.agreement.rec2_count_data;
          this.totalRec2PatientCountData =
            this.agreement.rec2_patient_count_data;
          this.need_rec2_patient = res.data.need_rec2_patient;

          // 事業所選択の初期値
          this.selectedOfficeId =
            this.agreement.offices.length > 0
              ? this.agreement.offices[0].id
              : 0;

          resolve(0);
        }
      );
    });
  }

  /** 勤怠連携情報取得 */
  private fetchKintaiLinkInfos() {
    const officeIds = this.agreement.offices.map((office: Office) => office.id);
    this.postJsonCheck(
      window.base_url + "/api/admin/kintai/officeinfo/get",
      {
        agreement_id: Number(this.$route.params.id),
        office_ids: officeIds,
      },
      (res) => {
        // 連携済みで情報があれば格納
        if (res.data.office_infos) {
          this.kintaiLinkInfos = res.data.office_infos;
        }
      }
    );
  }

  /** 選択中の事業所の勤怠連携情報を取得 */
  private get selectedOfficeKintaiLinkInfo(): KintaiLinkInfo {
    const target = this.kintaiLinkInfos.filter(
      (data: KintaiLinkInfo) => data.office_id == this.selectedOfficeId
    );

    return target.length ? target[0] : { ...DEFAULT_KINTAI_LINK_INFO };
  }

  /** 選択中の事業所の勤怠連携フラグを取得 */
  private get isAttendanceUse(): number {
    return this.selectedOffice.billing_info.is_attendance_use;
  }

  /** 勤怠治験情報取得 */
  private fetchChikenLinkInfos() {
    const officeIds = this.agreement.offices.map((office: Office) => office.id);
    console.log("param:", this.$route.params.id, officeIds);
    this.postJsonCheck(
      window.base_url + "/api/admin/chiken/officeinfo/get",
      {
        agreement_id: Number(this.$route.params.id),
        office_ids: officeIds,
      },
      (res) => {
        // 連携済みで情報があれば格納
        console.log(res.data.office_infos);
        if (res.data.office_infos) {
          this.chikenLinkInfos = res.data.office_infos;
        }
      }
    );
  }

  /** 選択中の事業所の治験連携情報を取得 */
  private get selectedOfficeChikenLinkInfo(): ChikenLinkInfo {
    const target = this.chikenLinkInfos.filter(
      (data: ChikenLinkInfo) => data.office_id == this.selectedOfficeId
    );

    return target.length ? target[0] : { ...DEFAULT_CHIKEN_LINK_INFO };
  }

  /** 選択中の事業所の治験連携フラグを取得 */
  private get isChikenUse(): number {
    return this.selectedOffice.billing_info.is_chiken_use;
  }

  /** 事業所選択肢 */
  private get officeChoices(): Choice[] {
    const choices: Choice[] = [];
    choices.push(
      ...this.agreement.offices.map((data: Office) => {
        return { text: data.office_name, value: data.id };
      })
    );
    return choices;
  }

  /** 選択中の事業所情報 */
  private get selectedOffice(): Office {
    if (this.selectedOfficeId == 0) {
      return { ...DEFAULT_OFFICE };
    } else {
      return this.agreement.offices.filter(
        (data: Office) => data.id == this.selectedOfficeId
      )[0];
    }
  }

  private get filteredRec2Count() {
    const officeId = this.selectedOfficeId;

    return this.totalRec2CountData.filter(
      (data: OfficeRec2Count) => data.office_id == officeId
    )[0];
  }

  private get isCarelogLink(): boolean {
    return this.selectedOffice.billing_info.portal_office_id
      ? Number(this.selectedOffice.billing_info.portal_office_id) != 0
      : false;
  }

  private get filteredRec2PatientCount() {
    const officeId = this.selectedOfficeId;

    return this.totalRec2PatientCountData.filter(
      (data: OfficeRec2Count) => data.office_id == officeId
    )[0];
  }
}
