













































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import {
  Agreement,
  AgreementHistory,
  DEFAULT_AGREEMENT_HISTORY,
} from "@/model/agreement";
import { DEFAULT_OFFICE, Office } from "@/model/office";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import AgreementInfo from "@/components/contractreception/AgreementInfo.vue";
import ContractApprovalStepper from "@/components/contractreception/ContractApprovalStepper.vue";
import BillingCommentViewer from "@/components/contractreception/common/BillingCommentViewer.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import { Choice } from "@/types";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import {
  KintaiLinkInfo,
  DEFAULT_KINTAI_LINK_INFO,
} from "@/model/agreement/kintaiLink";

@Component({
  components: {
    AgreementInfo,
    BillingCommentViewer,
    AppAuthButton,
    ContractApprovalStepper,
    ChangeContractDialog,
  },
})
export default class ContractReceptionDetail extends Mixins(
  AxiosMixin,
  UtilMixin
) {
  @Ref("contract-dialog")
  private readonly changeContractDialog!: ChangeContractDialog;

  public created(): void {
    this.updateData(true, true);
  }

  /** 選択中の事業所ID */
  private selectedOfficeId = 0;

  /** 勤怠連携情報(全事業所分) */
  private kintaiLinkInfos: KintaiLinkInfo[] = [];

  /** 対象契約情報 */
  private agreement: Agreement = {} as Agreement;

  private agreementHistory = { ...DEFAULT_AGREEMENT_HISTORY };

  /** 初期ステップ番号 */
  private initStepNo = 1;

  /* 契約(企業)編集 */
  private editAgreement(): void {
    this.$router.push({
      path: "/contractmanagement/edit/" + this.agreement.id,
    });
  }

  /** 各ステップでの保存・承認処理時 */
  private save(savingConfflag: number): void {
    // データ再取得で疑似的にリロード
    // 承認時は選択中事業所IDを変更させる
    const needUpdateSelectedOfficeId = savingConfflag == 0;
    this.updateData(needUpdateSelectedOfficeId, true);
    this.scrollTop();
  }

  /** 契約情報取得 */
  private async fetchAgreement(needUpdateSelectedOfficeId: boolean) {
    return new Promise((resolve) => {
      const id = Number(this.$route.params.id);
      this.postJsonCheck(
        window.base_url + "/api/admin/agreements/get",
        { is_display_confirm: 1, ids: [id], need_master: 1 },
        async (res) => {
          if (!res.data.agreements || res.data.agreements.length == 0) {
            resolve(0);

            // 契約が存在しない場合はアラートを出して遷移
            await this.$openAlert("指定した契約が存在しません。");
            location.href = "/contractreception/list";
            return;
          }

          this.agreement = res.data.agreements[0];
          this.agreementHistory = this.agreement.agreement_historys[0];

          // 未承認の事業所のみを抽出
          this.agreement = res.data.agreements.map((agreement: Agreement) => {
            if (!agreement.offices) {
              agreement.offices = [] as Office[];
              return agreement;
            }

            agreement.offices = agreement.offices.filter(
              (office: Office) => office.billing_info.confflag != 1
            );
            return agreement;
          })[0];

          // 事業所選択の初期値
          // 承認作業保存時や勤怠連携更新時などは選択中事業所を更新してほしくないので制御を入れる
          if (needUpdateSelectedOfficeId) {
            this.selectedOfficeId =
              this.agreement.offices.length > 0
                ? this.agreement.offices[0].id
                : 0;
          }

          resolve(0);
        }
      );
    });
  }

  /** 事業所削除 */
  private async deleteOffice(office: Office) {
    if (!(await this.$openConfirm("事業所を削除します。\nよろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/office/delete",
      { office: office },
      () => {
        this.$router.push({
          path: "/contractreception/list/",
        });
      }
    );
  }

  /** 企業情報変更履歴ボタンクリック */
  private viewAgreementHistory() {
    // 変更履歴確認用画面へ遷移
    this.$router.push({
      path: `/agreement/history/list/${this.$route.params.id}`,
    });
  }

  /** 事業所契約情報変更履歴ボタンクリック */
  private clickOfficeHistoryShow(id: number) {
    this.$router.push({
      path: `/office/history/list/${this.$route.params.id}?office_id=${id}`,
    });
  }

  /** 勤怠連携情報取得 */
  private fetchKintaiLinkInfos() {
    const officeIds = this.agreement.offices.map((office: Office) => office.id);
    this.postJsonCheck(
      window.base_url + "/api/admin/kintai/officeinfo/get",
      {
        agreement_id: Number(this.$route.params.id),
        office_ids: officeIds,
      },
      (res) => {
        // 連携済みで情報があれば格納
        if (res.data.office_infos) {
          this.kintaiLinkInfos = res.data.office_infos;
        }
      }
    );
  }

  /** 選択中の事業所の勤怠連携情報を取得 */
  private get selectedOfficeKintaiLinkInfo(): KintaiLinkInfo {
    const target = this.kintaiLinkInfos.filter(
      (data: KintaiLinkInfo) => data.office_id == this.selectedOfficeId
    );

    return target.length ? target[0] : { ...DEFAULT_KINTAI_LINK_INFO };
  }

  /** 事業所選択肢 */
  private get officeChoices(): Choice[] {
    const choices: Choice[] = [];
    choices.push(
      ...this.agreement.offices.map((data: Office) => {
        return { text: data.office_name, value: data.id };
      })
    );
    return choices;
  }

  /** 選択中の事業所情報 */
  private get selectedOffice(): Office {
    if (this.selectedOfficeId == 0) {
      return { ...DEFAULT_OFFICE };
    } else {
      return this.agreement.offices.filter(
        (data: Office) => data.id == this.selectedOfficeId
      )[0];
    }
  }

  private scrollTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }

  //変更届確認クリック
  private clickChangeContract() {
    this.changeContractDialog.open();
  }

  // 変更届の承認完了時
  private onCompleteChangeContract(historyAfter: AgreementHistory) {
    this.agreement.agreement_historys = [historyAfter];
  }

  /** データ更新 */
  private async updateData(
    needUpdateSelectedOfficeId: boolean,
    needUpdateStepNo: boolean
  ) {
    await this.fetchAgreement(needUpdateSelectedOfficeId);

    // 勤怠連携の更新時はステップ移動をしてほしくないので制御を入れる
    if (needUpdateStepNo) {
      this.calcInitStepNo();
    }
    this.fetchKintaiLinkInfos();
  }

  /** 初期ステップ番号計算 */
  private calcInitStepNo() {
    let nextStep = 1;
    switch (this.selectedOffice.billing_info.confflag) {
      case -2:
      default:
        nextStep = 1;
        break;
      case -1:
        nextStep = 2;
        break;
      case 0:
        nextStep = 3;
        break;
    }
    this.initStepNo = nextStep;
  }
}
