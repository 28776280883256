






































import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import {
  Billing,
  BillingFile,
  DEFAULT_BILLING,
} from "@/model/agreement/billing";
import { Office, DEFAULT_OFFICE, OfficeHistory } from "@/model/office";
import {
  DEFAULT_KINTAI_LINK_INFO,
  KintaiLinkInfo,
} from "@/model/agreement/kintaiLink";
import {
  DEFAULT_CHIKEN_LINK_INFO,
  ChikenLinkInfo,
} from "@/model/agreement/chikenLink";
import { VForm } from "@/types";
import OfficeInfoEditor from "@/components/contractreception/OfficeInfoEditor.vue";
import ChangeHistory from "@/components/common/ChangeHistory.vue";

@Component({ components: { OfficeInfoEditor, ChangeHistory } })
export default class OfficeEdit extends Mixins(AxiosMixin, UtilMixin) {
  /** フォーム */
  @Ref("office-editor") private readonly form!: VForm;

  /** 事業所 */
  private office: Office = { ...DEFAULT_OFFICE };
  /** 請求情報 */
  private billing: Billing = { ...DEFAULT_BILLING };
  /** 企業コード */
  private coCode = "";
  /** 変更履歴を残すフラグ */
  private saveHistoryLog = false;
  /** 勤怠連携情報 */
  private kintaiLinkInfo: KintaiLinkInfo = DEFAULT_KINTAI_LINK_INFO;
  /** 治験連携情報 */
  private chikenLinkInfo: ChikenLinkInfo = DEFAULT_CHIKEN_LINK_INFO;
  /** 事業所情報更新フラグ */
  private is_office_info_update = false;
  /** 事業所情報変更前の情報 */
  private oldOfficeInfo: OfficeHistory = { ...DEFAULT_OFFICE.office_info };

  /** 事業所情報更新時の処理 */
  @Watch("office.office_info", { deep: true }) private watchOfficeInfo(
    newValue: OfficeHistory
  ) {
    if (
      this.oldOfficeInfo.id &&
      JSON.stringify(newValue) !== JSON.stringify(this.oldOfficeInfo)
    ) {
      this.is_office_info_update = true;
    }
    this.oldOfficeInfo = { ...newValue };
  }

  public created(): void {
    this.fetch();
  }

  /** データ更新時の取得処理 */
  private async fetch() {
    await this.fetchAgreement();
    this.fetchKintaiOfficeInfo();
    this.fetchChikenOfficeInfo();
  }

  /** 契約情報の取得・事業所情報の格納 */
  private async fetchAgreement() {
    return new Promise((resolve) => {
      const agreementId = Number(this.$route.params.id);
      this.postJsonCheck(
        window.base_url + "/api/admin/agreements/get",
        {
          agreement_id: agreementId,
          ids: [agreementId],
          is_display_confirm: 1,
          need_master: 1,
        },
        (res) => {
          if (this.$route.query.office_id) {
            this.office = res.data.agreements[0].offices.find(
              (o: Office) => o.id == Number(this.$route.query.office_id)
            );
            this.billing = this.office.billing_info;
            if (!this.billing.billing_files) {
              this.billing.billing_files = [] as BillingFile[];
            }
            this.coCode = res.data.agreements[0].co_code;
            this.billing.change_message = "";
          }

          resolve(0);
        }
      );
    });
  }

  /** 勤怠連携情報取得 */
  private fetchKintaiOfficeInfo() {
    this.postJsonCheck(
      window.base_url + "/api/admin/kintai/officeinfo/get",
      {
        agreement_id: Number(this.$route.params.id),
        office_ids: [this.billing.office_id],
      },
      (res) => {
        // 勤怠連携されていない場合は何もしない
        if (!res.data.office_infos) {
          return;
        }
        this.kintaiLinkInfo = res.data.office_infos[0];
      }
    );
  }

  /** 勤怠連携情報取得 */
  private fetchChikenOfficeInfo() {
    this.postJsonCheck(
      window.base_url + "/api/admin/chiken/officeinfo/get",
      {
        agreement_id: Number(this.$route.params.id),
        office_ids: [this.billing.office_id],
      },
      (res) => {
        // 勤怠連携されていない場合は何もしない
        if (!res.data.office_infos) {
          return;
        }
        this.chikenLinkInfo = res.data.office_infos[0];
      }
    );
  }

  //保存ボタンクリック
  private async saveBillingInfo(): Promise<void> {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }

    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }

    if (!this.saveHistoryLog) {
      this.billing.change_message = "";
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/billing_info/save",
      {
        billing: this.billing,
        agreement_id: Number(this.$route.params.id),
        saving_confflag: 1,
        is_office_info_update: this.is_office_info_update,
        office_info: this.office.office_info,
      },
      () => {
        this.$router.go(-1);
      }
    );
  }

  //キャンセルボタンクリック
  private clickCancel() {
    this.$router.push({
      path: "/contractmanagement/detail/" + this.$route.params.id,
    });
  }

  /** 事業所削除 */
  private async deleteOffice() {
    if (!(await this.$openConfirm("事業所を削除します。\nよろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/office/delete",
      { office: this.office },
      () => {
        this.$router.push({
          path: "/contractmanagement/list/",
        });
      }
    );
  }
}
